<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <v-row no-gutters fill-height>
      <v-col class="md-9 pr-2 media-col" align="center" justify="center">
        <v-card
          v-if="!file"
          class="mt-6"
          flat
          elevation="0"
          fill-height
          align="center"
          justify="center"
          height="200"
          width="200"
        >
          <v-card-text class="pt-8 my-auto">
            <media-upload-modal :accept="accept" @onUploaded="onFileUploaded">
              <v-row
                align="center"
                justify="center"
                class="fill-height pt-8"
              >
              <v-col>
                <div class="pa-3">
                  <v-icon x-large>mdi-cloud-upload</v-icon>
                </div>
                <div class="pa-3 text-center">
                  <small>
                    <slot>
                      Drag your file here to upload<br />
                      or click to browse
                    </slot>
                  </small>
                </div>
              </v-col>
              </v-row>
            </media-upload-modal>
          </v-card-text>
        </v-card>

        <div v-if="file && mediaForm">
          <component :is="mediaForm" v-model="file" :editable="false" />
        </div>
      </v-col>

      <v-col class="md-3 pa-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            label="Name"
            required
            v-model="model.name"
            :rules="nameRules"
          ></v-text-field>
          <v-textarea
            label="Description"
            auto-grow
            v-model="model.description"
            :rules="descriptionRules"
          ></v-textarea>
        </v-form>
      </v-col>
    </v-row>

    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          v-if="!uploading"
          @click="onSave"
          :disabled="!canSave || uploading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar'
import mediaUploadModal from '@/components/media/upload/modal.vue'
import mediaPage from '@/components/mixins/media-page.vue'
import { media as mediaApi } from '@/services/api'

export default {
  mixins: [mediaPage],
  components: {
    appToolbar,
    mediaUploadModal
  },
  data() {
    return {
      crumbs: [
        { text: 'Media Library', to: { name: 'media-index' } },
        { text: 'Create' }
      ],
      uploading: false,
      model: {
        name: '',
        description: ''
      },
      valid: true
    }
  },
  computed: {
    canSave() {
      return this.file != null
    }
  },
  methods: {
    async onFileUploaded(upload) {
      this.file = upload.file
      this.fileType = upload.fileType
      this.model.name = this.file.name.split('.').shift()
      this.model.description = this.model.name
      this.model.mediaType = this.fileType

      this.uploading = false
    },
    async onCancel() {
      this.file = null
      this.fileType = null
      await this.$router.push({ name: 'media-index' })
    },
    async onSave() {
      await this.$refs.form.validate()

      this.uploading = true

      if (this.valid) {
        const response = await mediaApi.api.postFile(this.file, this.model)
        await this.$router.push({
          name: 'media-detail',
          params: { mediaId: response.id }
        })
      }

      this.uploading = false
    }
  }
}
</script>

<style></style>
