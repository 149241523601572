<template>
  <questions-table 
    :show-relations="true" 
    :show-add-button="false"
    :media-options="mediaOptions"
    :useEditModel="false"
    @row:view="view"
  />
</template>

<script>
import contentPageMixin from '@/components/mixins/content-page'
import QuestionsTable from '@/components/knowledgeCheck/questions-table.vue'

export default {
  mixins: [contentPageMixin],
  components: {
    QuestionsTable
  },
  data() {
    return {}
  },
  computed: {
    localCrumbs() {
      const items = [
        {
          text: 'Questions',
          to: { name: 'questions-create', params: {} }
        }
      ]

      return items
    },
    filterCount() {
      return this.advancedFilters.reduce(function (a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    },
    mediaOptions() {
      return {
        showUpload: true,
        title: 'Media Selector',
        moduleId: this.moduleId,
        componentId: this.componentId,
        procedureId: this.procedureId,
        topicId: this.topicId
      }
    }
  },
  mounted() {},
  methods: {
    view(item){
      this.$router.push({ name: 'question-detail', params: { questionId: item.id } })
    }
  }
}
</script>

<style scoped></style>
