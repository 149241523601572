<template>  
  <v-card tile flat v-if="model">
    <v-card-text style="max-height:80%">
      <v-form ref="form" v-model="valid" @cancel="onCancel">
        <v-textarea
          ref="questionInput"
          label="Question Text"
          auto-grow
          v-model="model.text"
          :rules="questionTextRules"
          validate-on-blur
          counter="1000"
        ></v-textarea>

        <v-row>
          <v-col cols="11">
            <v-card max-width="100%" min-width="350px" flat v-if="questionMediaId">
              <v-responsive>
                <media-preview
                  :mediaId="questionMediaId"
                  height="350"
                >
                </media-preview>
              </v-responsive>
            </v-card>
           
            <media-selector
              v-model="selectImage"
              :options="mediaOptions"
              @selected="onImageSelected"
            />
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="selectImageFor(model)">
              <v-icon>mdi-camera</v-icon> 
            </v-btn>
            <v-btn icon v-if="model.mediaItem"  @click="removeImageFor(model)">
              <v-icon>mdi-camera-off</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        

        <h4 class="mt-2">Choices</h4>

        <v-select
          :items="questionTypes"
          label="Answer Type"
          item-text="text"
          item-value="id"
          v-model="model.questionType"
          style="max-width:250px"
          @change="onQuestionTypeChange"
        ></v-select>
        
        <div v-for="choice in model.choices">
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-if="model.questionType === 'checkbox'"
                v-model="choice.isAnswer"
                dense
                @change="onAnswerChange(choice)"
              ></v-checkbox>
              <v-radio-group
                v-if="model.questionType === 'radio'"
                dense
                v-model="choice.isAnswer"
                @change="onAnswerChange(choice)"
              >
                <v-radio
                  dense
                  :value="true"
                  @change="onAnswerChange(choice)"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="10">
              <v-textarea
                ref="choiceInput"
                label="Choice Text"
                dense
                auto-grow
                rows="2"
                v-model="choice.label"
              ></v-textarea>
              
              <v-card max-width="100%" min-width="350px" flat v-if="choice.mediaItemId">
                <v-responsive>
                  <media-preview
                    :mediaId="choice.mediaItemId"
                    height="250"
                  ></media-preview>
                </v-responsive>
              </v-card> 
              
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right text-no-wrap">
              <v-btn icon @click="selectImageFor(choice)">
                <v-icon>mdi-camera</v-icon> 
              </v-btn>
              <v-btn v-if="choice.mediaItem" icon @click="removeImageFor(choice)">
                <v-icon>mdi-camera-off</v-icon>
              </v-btn>
              <v-btn icon @click="removeChoice(choice)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-btn @click="addChoice" text color="primary">
          <v-icon>mdi-plus</v-icon>
          Add Choice</v-btn
        >
      </v-form>
    </v-card-text>
   
    <v-card-actions>
      <v-btn v-if="model.id" plain color="red" @click="onDelete">Delete</v-btn>
      <v-spacer></v-spacer>
      <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
      <v-btn tile large color="primary" @click="onSave" :disabled="!valid || !isChoiceCreated || !isAnswerSelected">
        Save
      </v-btn>
      <v-btn tile large color="primary" @click="onPublish" :disabled="!valid  || !isChoiceCreated || !isAnswerSelected">
        Publish
      </v-btn>
    </v-card-actions>
    <v-alert text type="warning" v-if="!isChoiceCreated && formTouched" class="alert">
      At least one choice is required
    </v-alert>
    <v-alert text type="warning" v-if="!isAnswerSelected && formTouched" class="alert">
      At least one correct answer is required
    </v-alert>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { nanoid } from 'nanoid'
import MediaSelector from '@/components/media/selectors/media-selector.vue'
import MediaPreview from '@/components/media/media-preview.vue'

export default {
  components: {
    MediaPreview,
    MediaSelector
  },
  props: {
    value: { type: Object, required: true },
    mediaOptions: { type: Object, required: false },
    resetSignal: {type: Boolean, default: false }
  },
  data() {
    return {
      model: {
        text: '',
        questionType: 'radio',
        choices: []
      },
      questionTextRules: [
        (v) => !!v || 'Question Text is required',
      ],
      answerRules: [
        (v) => (v && v.length > 0) || 'At least one answer is required',
      ],
      formTouched: false,
      selectImage: false,
      selectImageForItem: null,
      questionTypes: [
        {
          id: 'radio',
          text: 'Single Choice'
        },
        {
          id: 'checkbox',
          text: 'Multiple Choice'
        }
      ],
      valid: true
    }
  },
  computed:{
    questionMediaId() {
      return this.model.mediaItem ? this.model.mediaItem.mediaId || this.model.mediaItem.id : null
    },
    isChoiceCreated() {
      return this.model.choices.some(choice => choice.label?.trim() !== '')
    },
    isAnswerSelected() {
      return this.model.choices.some(choice => choice.isAnswer)
    }
  },
  watch: {
    value(val) {
      this.ensureQuestion(val)
    },
    resetSignal(newVal) {
      if (newVal) {
        this.onCancel()
        this.$emit('resetDone')
      }
    }
  },
  mounted() {
    if (this.value) {
      this.ensureQuestion(this.value)
    }
  },
  methods: {
    ensureQuestion(val) {
      
      val.questionType = val.questionType || 'radio'
      
      if (val.mediaItem) {
        val.mediaItemId = val.mediaItem.mediaId || val.mediaItem.id
      }

      val.choices = val.choices || []
      val.choices.forEach((c) => {
        if (c.mediaItem) {
          c.mediaItemId = c.mediaItem.mediaId || c.mediaItem.id
        }
      })
      this.model = _.cloneDeep(val)

      this.$nextTick(() => {
        this.$refs.questionInput.focus()
      })
    },
    removeImageFor(item) {
      item.mediaItem = null
      item.mediaItemId = null
    },
    selectImageFor(item){
      this.selectImageForItem = item
      this.selectImage = true
    },
    onImageSelected(image) {
      this.selectImageForItem.mediaItem = image
      this.selectImageForItem.mediaItemId = image.id
      this.selectImage = false
    },
    addChoice() {
      this.formTouched = true
      this.model.choices.push({
        label: '',
        value: nanoid(),
        isAnswer: false,
        order: this.model.choices.length + 1
      })

      this.$nextTick(() => {
        this.$refs.choiceInput[this.model.choices.length - 1].focus()
      })
    },
    onQuestionTypeChange(){
      this.model.choices.forEach((c) => {
        c.isAnswer = false
      })
    },
    onAnswerChange(choice) {
      if (choice.isAnswer && this.model.questionType === 'radio') {
        this.model.choices.forEach((c) => {
          if (c !== choice) {
            c.isAnswer = false
          }
        })
      }
    },    
    removeChoice(choice) {
      this.model.choices = this.model.choices.filter((c) => c !== choice)
    },
    onSave() {
      this.formTouched = true
      this.$refs.form.validate()
      if (!this.valid || !this.isChoiceCreated || !this.isAnswerSelected) {
        return
      }

      this.model.choices.forEach((c, idx) => {
        c.order = idx + 1
      })

      this.$emit('save', this.model)
      this.formTouched = false
    },
    onPublish() {
      this.$emit('publish', this.model)
    },
    onCancel() {
      this.formTouched = false
      this.$refs.form.reset()
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete', this.model)
    }
  }
}
</script>

<style scoped>
.alert {
  padding: 5px 10px;
  font-size: 0.8rem;
}
</style>
