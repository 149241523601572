import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/lookups`

const modules = () => {
  return apiClient.get(path, { _ignoreAuth: true }).then(
    (response) => {
      return response.data
    },
    (error) => {
      if (error.response.status == 401) return {}
    }
  )
}

const treeview = async () => {
  const response = await modules()
  const treeview = response.map((module) => {
    return {
      id: module.id,
      name: `(M) ${module.name}`,
      entityType: 'module',
      children: module.components.map((component) => {
        const topics = component.topics.map((topic) => {
          return {
            id: topic.id,
            name: `(T): ${topic.name}`,
            entityType: 'topic'
          }
        })
        const procedures  = component.procedures.map((procedure) => {
          return {
            id: procedure.id,
            name: `(P): ${procedure.name}`,
            entityType: 'procedure'
          }
        })

        return {
          id: component.id,
          name: `(C) ${component.name}`,
          entityType: 'component',
          children: [...topics,...procedures]
        }
      })
    }
  })

  return treeview
}

export default {
  modules,
  treeview
}
