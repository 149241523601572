<template>
  <v-dialog 
    v-model="show" 
    max-width="90%" 
    min-widht="500px" 
    persistent
    scrollable
  >
    <v-card>
      <v-app-bar flat elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <question-form
          v-model="question"
          :mediaOptions="mediaOptions"
          :resetSignal="resetSignal"
          @publish="onPublish"
          @delete="onConfirmDelete"
          @save="onSave"
          @cancel="cancel"
          @resetDone="resetSignal = false"
        />
      </v-card-text>
      <delete-dialog
        v-model="showDeleteConfirmation"
        @onDelete="onDelete"
      >
      </delete-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { questions as questionsApi } from '@/services/api'
import QuestionForm from '@/components/knowledgeCheck/questions/form.vue'
import DeleteDialog from './delete.vue'

export default {
  components: {
    DeleteDialog,
    QuestionForm
  },
  props: {
    mediaOptions: { type: Object, default: null },
    question: { type: Object, required: true },
    title: { type: String, default: 'Edit Question' },
    value: Boolean
  },
  data() {
    return {
      showDeleteConfirmation: false,
      resetSignal: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    question(val) {
      if (val) {
        this.question.mediaItem = val.mediaItem || {}
        this.question.mediaItemId = val.mediaItemId || null
      }
    },
    show() {}
  },
  methods: {
    cancel() {
      this.show = false
      this.resetSignal = true
      this.$emit('cancel')
    },
    async doSave(model) {
      if (model.id) {
        await questionsApi.put(model.id, model)
      } else {
        const response = await questionsApi.post(model)
        model.id = response.data.id
      }
      return model
    },
    async onPublish(model) {
      const result = await this.doSave(model)
      await questionsApi.publish(result.id)

      this.show = false
      this.$emit('save', model)
      this.$emit('publish', model)
    },
    async onSave(model) {
      await this.doSave(model)
      this.show = false
      this.$emit('save', model)
    },
    async onConfirmDelete() {
      this.showDeleteConfirmation = true
    },
    async onDelete() {
      await questionsApi.delete(this.question.id)
      this.show = false
      this.$emit('delete', this.question)
    }
  }
}
</script>
