<template>
  <div>
    <page-list
      title="Modules"
      :to="{ name: 'module-create' }"
      to-label="Add"
      :headers="headers"
      :items="modules"
      :paging="paging"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @search="onSearch"
      @clear="onSearchClear"
      @page:changed="onPageChange"
      @row:view="view"
      @sortBy:changed="onSortByChanged"
      @sortDesc:changed="onSortDescChanged"
      :filterCount="filterCount"
    >
      <template v-slot:filters>
        <v-checkbox
          v-model="advancedFilters"
          value="1"
          label="Include archived modules"
          @click="includeArchived = !includeArchived"
        ></v-checkbox>
      </template>

      <template v-slot:actions>
        <v-menu bottom left offset-y pa-5>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group>

              <v-list-item @click="showSort">
                <v-list-item-icon>
                  <v-icon>mdi-sort</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Set Sort Order</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.status="{ item }">
        <span style="text-transform: capitalize">
          {{ item.version + ' / ' + item.status }}
        </span>
      </template>

      <template v-slot:item.publishDateTime="{ item }">
        {{ item.publishDateTime | dateFormat }}
      </template>

    </page-list>
  </div>
</template>

<script>
import _ from 'lodash'
import { modules as modulesApi } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'

export default {
  components: { pageList },
  data() {
    return {
      modules: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Version/Status', value: 'status' },
        { text: 'Published', value: 'publishDateTime' }
        /* Disabling actions until functions 
        other than edit necessary:
        { text: '', value: 'actions', sortable: false } */
      ],
      footerProps: {
        showItemsPerPage: false
      },
      advancedFilters: [],
      includeArchived: false,
      loaded: false,
      paging: {},
      search: '',
      sortBy: 'order',
      sortDesc: false
    }
  },
  computed: {
    filterCount() {
      return this.advancedFilters.reduce(function (a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    }
  },
  mounted() {
    this.loadModules()
  },
  methods: {
    async loadModules() {
      const response = await modulesApi.get(null, {
        params: {
          term: this.search,
          includeArchived: this.includeArchived,
          page: this.paging.currentPage
        }
      })

      this.paging = response.data.meta.paging
      this.modules = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.loadModules()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.loadModules()
    },
    onSortByChanged(sortBy) {
      this.sortBy = sortBy
      this.modules = _.orderBy(this.modules, [sortBy], [this.sortDesc ? 'desc' : 'asc'])
    },
    onSortDescChanged(sortDesc) {
      this.sortDesc = sortDesc
      this.modules = _.orderBy(this.modules, [this.sortBy], [sortDesc ? 'desc' : 'asc'])
    },
    showSort(){
      this.$router.push({
        name: 'module-sort'
      })
    },
    view(item) {
      this.$router.push({
        name: 'module-detail',
        params: { moduleId: item.id }
      })
    }
  }
}
</script>

<style scoped></style>
