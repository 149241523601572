//
// Default Theme
//
export default {
  name: 'iip',
  title: 'IIP',
  logo: 'logo.png',
  favicon: 'favicon.ico',
  helpUrl: 'https://4lnsteam.atlassian.net/servicedesk/customer/portal/5',
  location: {
    latitude: 21.4097967,
    longitude: -157.9160812,
    mgrs: '04Q FJ 128 584'
  }
}
