<template>
  <div>
    <v-card v-if="model" flat tile>
      <v-card-text>
        <v-row>
          <template v-if="model.coverImage">
            <v-col :sm="6" :xs="12" style="padding: 10px" class="text-center">
              <div style="background-color: #fafafa">
                <cover-image-selector
                  class="pr-2 text-right d-flex justify-end"
                  :value="model"
                  :mediaSelectorOptions="mediaSelectorOptions"
                  @imageUpdate="onImageUpdate"
                />
              </div>
              <cover-image v-model="model.coverImage" />
              <div class="overlay"></div>
            </v-col>
          </template>

          <template v-if="!model.coverImage">
            <v-col class="md-9 pr-2 media-col text-center" justify="center">
              <v-card
                v-if="!file"
                class="mt-6 mx-auto"
                flat
                elevation="0"
                fill-height
                justify="center"
                height="200"
                width="200"
              >
                <v-card-text class="pt-8 my-auto">
                  <media-upload-modal
                    :accept="accept"
                    @onUploaded="onFileUploaded"
                  >
                    <v-row
                      align="center"
                      justify="center"
                      class="fill-height lighten-4"
                    >
                    <v-col>
                      <div class="pa-3">
                        <v-icon x-large>mdi-cloud-upload</v-icon>
                      </div>
                      <div class="pa-3 text-center">
                        <small>
                          <slot>
                            Drag your file here to upload<br />
                            or click to browse
                          </slot>
                        </small>
                      </div>
                    </v-col>
                    </v-row>
                  </media-upload-modal>
                </v-card-text>
              </v-card>

              <div v-if="file && mediaForm">
                <component :is="mediaForm" v-model="file" :editable="false" />
              </div>
            </v-col>
          </template>

          <v-col :sm="6" :xs="12" class="md-3 pa-2">
            <v-form ref="form" v-model="valid">
              <v-text-field
                label="Name"
                required
                v-model="model.name"
                :rules="nameRules"
              ></v-text-field>
              <v-textarea
                label="Description"
                auto-grow
                v-model="model.description"
                :rules="descriptionRules"
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="model.id">
          <v-col cols="12">
            <editor
              v-model="model.content"
              placeholder="add topic content"
              :options="options"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-policy:sysadmin
          v-if="model.id"
          icon
          @click="showCode = !showCode"
          small
        >
          <v-icon>mdi-code-tags</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click="onSave"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <div v-policy:sysadmin v-if="showCode && model.id" class="pt-4">
      <pre><code>{{ model.content }}</code></pre>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import contentPageMixin from '@/components/mixins/content-page'
import editor from '@/components/tiptap/editor'
import coverImageSelector from '../../components/media/cover-image-selector.vue'
import coverImage from '@/components/mixins/cover-image'
import mediaUploadModal from '@/components/media/upload/modal.vue'
import mediaPage from '@/components/mixins/media-page.vue'

export default {
  mixins: [contentPageMixin, coverImage, mediaPage],
  components: {
    editor,
    coverImageSelector,
    mediaUploadModal
  },
  props: {
    value: { type: Object, required: true },
    label: { type: String, default: 'Create' },
    mediaSelectorOptions: { type: Object, required: true }
  },
  data() {
    return {
      content: null,
      model: {},
      valid: true,
      file: null,
      media: {},
      descriptionRules: [(v) => !!v || 'Description is required'],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ],
      showCode: false
    }
  },
  computed: {
    ...mapGetters('context', ['topic', 'module', 'component']),
    isNew() {
      return this.model.id ? true : false
    },
    options() {
      return {
        entityId: this.topic.id,
        entityType: 'topic',
        moduleId: this.module.id,
        componentId: this.component.id,
        selected: this.topic.coverImageId
      }
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.$emit('save', this.model, this.file)
    },
    async onFileUploaded(upload) {
      this.file = upload.file
      this.fileType = upload.fileType
      if (!this.model.name) {
        this.model.name = this.file.name.split('.').shift()
      }
      if (!this.model.description) {
        this.model.description = this.model.name
      }
      this.model.mediaType = this.fileType

      this.uploading = false
    },
    onImageUpdate(item) {
      this.model.coverImage = item.coverImage
      this.model.coverImageId = item.coverImageId
    }
  }
}
</script>

<style></style>
