<template>
  <div>
    <v-row>
      <v-col>
        <h2>{{ title }}</h2>
      </v-col>
      <v-spacer />
      <v-col class="text-right" v-if="showAddButton">
        <v-btn v-if="to" color="primary" :to="to"> {{ toLabel }} </v-btn>
        <v-btn v-else-if="raiseAddEvent" color="primary" @click="onAdd">
          {{ toLabel }}
        </v-btn>
        <slot name="actions"></slot>
      </v-col>
    </v-row>

    <v-form @submit="onSearch" onSubmit="return false;">
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            label="filter"
            clearable
            clear-icon="mdi-close mdi-sm"
            color="default"
            full-width
            dense
            solo
            hide-details="auto"
            @click:clear="onSearchClear"
          >
            <template v-slot:prepend-inner>
              <v-icon title="common searches" small> mdi-text-search </v-icon>
            </template>

            <template v-slot:append>
              <v-badge
                v-if="useFilters"
                :value="hasFilters"
                :content="filterCount"
                overlap
                bordered
              >
                <v-icon
                  title="Add advanced filters"
                  @click="showFilters = !showFilters"
                >
                  mdi-filter-variant
                </v-icon>
              </v-badge>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="showFilters && useFilters" no-gutters>
        <slot name="filters"></slot>
        <v-col></v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-btn class="searchButton" color="primary" dark type="submit">
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="footerProps"
          :page="paging.currentPage"
          :page-count="paging.totalPages"
          :items-per-page="itemsPerPage"
          :server-items-length="paging.totalItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="elevation-1 margin"
          :item-class="itemClass"
          item-key="id"
          @click:row="onViewRow"
          @pagination="onPageChange"
          @update:sort-by="onSortByChanged"
          @update:sort-desc="onSortDescChanged"
        >
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot :name="name" v-bind="slotData" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    title: { type: String, required: true },
    to: { type: Object, required: false },
    toLabel: { type: String, default: 'Add' },
    raiseAddEvent: { type: Boolean, default: false },
    useFilters: { type: Boolean, default: true },
    filterCount: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
    itemsPerPage: { type: Number, default: 500 },
    headers: { type: Array, required: true },
    serverItemsLength: { type: Number, default: -1 },
    showAddButton: { type: Boolean, default: true },
    sortBy: { type: String, default: null },
    sortDesc: { type: Boolean, default: true },
    paging: {
      type: Object,
      default: () => {
        return { currentPage: 1 }
      }
    }
  },
  data() {
    return {
      search: null,
      showFilters: false,
      footerProps: {
        showItemsPerPage: false
      }
    }
  },
  computed: {
    hasFilters() {
      return this.filterCount > 0
    }
  },
  watch: {
    search(val) {
      this.onSearchChange(val)
    }
  },
  methods: {
    itemClass(item) {
      return item.isDeleted ? 'text-decoration-line-through' : ''
    },
    onAdd() {
      this.$emit('add')
    },
    onSearch() {
      this.$emit('search', this.search)
    },
    onSearchClear() {
      this.$emit('clear')
    },
    onSearchChange: _.debounce(function (term) {
      if (term && term.length > 2) {
        this.onSearch()
      }
    }, 300),
    onPageChange(pagination) {
      if (
        this.paging.currentPage &&
        this.paging.currentPage != pagination.page
      ) {
        this.$emit('page:changed', pagination)
      }
    },
    onSortByChanged(sortBy) {
      this.$emit('sortBy:changed', sortBy)
    },
    onSortDescChanged(sortDesc) {
      this.$emit('sortDesc:changed', sortDesc)
    },
    onViewRow(item) {
      this.$emit('row:view', item)
    }
  }
}
</script>

<style>
.searchButton {
  position: relative;
  top: 15px;
  right: 0;
}
</style>
