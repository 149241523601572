import apiClient from './clients/api-client'
import { API_URL } from '@/config'
import { addKnowledgeCheck } from './knowledge-check'

const path = `${API_URL}/modules`

const client = apiClient.build(path)

client.archive = (id) => {
  const archivePath = `${client.__api.entityPath(path, id)}/archive`
  return client.__api.put(archivePath).then((response) => response.data)
}

client.restore = (id) => {
  const archivePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api.put(archivePath).then((response) => response.data)
}

client.cover = (id, referenceId) => {
  const p = `${client.__api.entityPath(path, id)}/cover/${referenceId}`
  return client.__api.put(p).then((response) => response.data)
}

client.validate = (id, params) => {
  const p = `${client.__api.entityPath(path, id)}/publish/validate`
  params = params || {}
  return client.__api.post(p, params).then((response) => response.data)
}

client.publish = (id, params) => {
  const p = `${client.__api.entityPath(path, id)}/publish`
  params = params || {}
  return client.__api.post(p, params).then((response) => response.data)
}

client.updateComponentSettings = (id, componentId, params) => {
  const p = `${client.__api.entityPath(path, id)}/components/${componentId}/settings`
  params = params || {}
  return client.__api.put(p, params).then((response) => response.data)
}

client.updateComponentOrder = (id, params) => { 
  const p = `${client.__api.entityPath(path, id)}/components/order`
  params = params || {}
  return client.__api.put(p, params).then((response) => response.data)
}

addKnowledgeCheck(client)

export default client
