import apiClient from './clients/api-client'
import { API_URL } from '@/config'

const path = `${API_URL}/questions`

const client = apiClient.build(path)

client.search = (filters) => {
  const p = `${path}/search`
  return client.__api.post(p, filters).then((response) => {
    let paging = {
      hasNextPage: false
    }

    if (response.data.paging) {
      paging = response.data.paging
    }

    return {
      paging: paging,
      data: response.data
    }
  })
}

client.restore = (id) => {
  const archivePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api.put(archivePath).then((response) => response.data)
}

client.archive = (id, params) => {
  const p = `${client.__api.entityPath(path, id)}/archive`
  params = params || {}
  return client.__api.put(p, params).then((response) => response.data)
}

client.publish = (id, params) => {
  const p = `${client.__api.entityPath(path, id)}/publish`
  params = params || {}
  return client.__api.put(p, params).then((response) => response.data)
}

client.relations = (id) => {
  const p = `${client.__api.entityPath(path, id)}/relations`
  return client.__api.get(p).then((response) => response.data)
}

client.removeRelation = (id, relationId) => {
  const p = `${client.__api.entityPath(path, id)}/relations/${relationId}`
  return client.__api.delete(p).then((response) => response.data)
}

client.addRelations = (id, payload) => {
  const p = `${client.__api.entityPath(path, id)}/relations`
  return client.__api.put(p, payload).then((response) => response.data)
}

client.copy = (id, payload) => {
  const p = `${client.__api.entityPath(path, id)}/copy`
  return client.__api.put(p, payload).then((response) => response.data)
}

export default client
