<template>
  <div class="mt-2">
    <v-row>
      <v-col class="text-h6"> References </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :disable-pagination="true"
      :hide-default-footer="true"
      class="elevation-1"
      disable-sort
      @click:row="view"
    >
      <template v-slot:item.name="{ item }">
        {{ itemName(item) }}
      </template>

      <template v-slot:item.entityType="{ item }">
        <span class="text-capitalize">{{ item.entityType }}</span>
      </template>

      <template v-slot:item.actions="{}">
        <div class="text-right"></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash'

const truncateOptions = {
  length: 25
}

export default {
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', width: '40%' },
        { text: 'Type', value: 'entityType', width: '40%' },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  methods: {
    itemName(item) {
      let name = item.moduleName

      switch (item.entityType) {
        case 'component':
          name = `${name}/${item.componentName}`
          break
        case 'topic':
          name = `${name}/${item.componentName}/${item.topicName}`
          break
        case 'procedure':
          name = `${name}/${item.componentName}/${item.procedureName}`
          break
        case 'procedurestep':
          name = `${name}/${item.componentName}/${item.procedureName}/${item.procedureStepName}`
          break
        case 'question':
          name = `question/${_.truncate(item.questionText, truncateOptions)}`
          break
        case 'question-choice': 
          name = `question/${_.truncate(item.questionText, truncateOptions)}/choice/${item.questionChoiceLabel}`
          break
        default:
          name = item.entityType
          break
      }
      return name
    },
    view(item) {
      const name = `${item.entityType}-detail`
      const params = {
        moduleId: item.moduleId,
        componentId: item.componentId,
        topicId: item.topicId,
        procedureId: item.procedureId,
        procedureStepId: item.procedureStepId,
        questionId: item.questionId,
        questionChoiceId: item.questionChoiceId
      }
      
      this.$router.push({
        name,
        params
      })
    }
  }
}
</script>
