import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'
import { addKnowledgeCheck } from './knowledge-check'

const basePath = `${API_URL}/modules`

const build = function (moduleId) {
  const path = `${basePath}/${moduleId}/components`

  const client = apiClient.build(path)

  client.archive = (id) => {
    const archivePath = `${client.__api.entityPath(path, id)}/archive`
    return client.__api.put(archivePath).then((response) => response.data)
  }

  client.restore = (id) => {
    const archivePath = `${client.__api.entityPath(path, id)}/restore`
    return client.__api.put(archivePath).then((response) => response.data)
  }

  client.cover = (id, referenceId) => {
    const p = `${client.__api.entityPath(path, id)}/cover/${referenceId}`
    return client.__api.put(p).then((response) => response.data)
  }

  client.validate = (id, params) => {
    const p = `${client.__api.entityPath(path, id)}/publish/validate`
    params = params || {}
    return client.__api.post(p, params).then((response) => response.data)
  }

  client.publish = (id, params) => {
    const p = `${client.__api.entityPath(path, id)}/publish`
    params = params || {}
    return client.__api.post(p, params).then((response) => response.data)
  }

  client.updateTopicOrder = (id, params) => { 
    const p = `${client.__api.entityPath(path, id)}/topics/order`
    params = params || {}
    return client.__api.put(p, params).then((response) => response.data)
  }

  client.updateProcedureOrder = (id, params) => { 
    const p = `${client.__api.entityPath(path, id)}/procedures/order`
    params = params || {}
    return client.__api.put(p, params).then((response) => response.data)
  }

  addKnowledgeCheck(client)

  return client
}

export default { build }
