<template>
  <div>
    <app-toolbar :crumbs="localCrumbs">
      <v-spacer></v-spacer>
      
    </app-toolbar>

    <div>Set module sort order</div>
    <v-card flat tile>
        <v-card-text>
          <v-list dense>
            <draggable
              v-model="modules"
              animation="200"
              handlesdfg=".drag-handle"
              ghost-class="drag-item-ghost"
              drag-class="drag-dragging-class"
              @start="drag = true"
              @end="drag = false"
              @change="onChange"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <v-list-item
                  v-for="m in modules"
                  :key="m.itemId"
                  class="drag-handleafdas"
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon class="drag-handle">mdi-drag</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ m.displayOrder }}. {{ m.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </transition-group>
            </draggable>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel"> Cancel </v-btn>
          <v-btn tile large color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import { organizations as organizationsApi } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'

export default {
  mixins: [contentPageMixin],
  components: {
    draggable
  },
  data() {
    return {
      drag: false,
      modules: []
    }
  },
  computed: {
    localCrumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Settings',
        disabled: true
      })
      items.push({
        text: 'Sort',
        disabled: true
      })
      return items
    }
  },
  async mounted() {
    this.load()
  },
  watch: {
  },
  methods: {
    async load(){
      const modules = await organizationsApi.getModuleSortOrder()

      const orderSorter = 'displayOrder'
      const nameSorter = item => item.name.toLowerCase()
      const arr  = _.sortBy(modules, [orderSorter, nameSorter])
      arr.forEach((item, idx) => {
        item.displayOrder = idx + 1
      }) 

      this.modules = [...arr]
    },
    cancel(){
      this.$router.go(-1)
    },
    onChange(){
      this.modules.map((m, idx) => {
        m.displayOrder = idx + 1
      })
    },
    async save() {
      const payload = _.chain(this.modules)
                    .keyBy('itemId')
                    .mapValues('displayOrder')
                    .value()

      await organizationsApi.setModuleSortOrder(payload)
      this.$router.go(-1)
    }
  }
}
</script>
