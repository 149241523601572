<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Relations</div>
      <v-spacer></v-spacer>
      <v-btn v-policy:question-update="question" @click="add" color="primary">
        Add
      </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="sorted"
        item-key="id"
        :disable-pagination="true"
        :hide-default-footer="true"
        disable-sort
      >
      <template v-slot:item.name="{item}">
        ({{item.entityType[0].toUpperCase()}}) {{ item.name | truncate(250) }} 
      </template>

      <template v-slot:item.actions="{item}">
        <div class="text-right">
          <confirmation-dialog
            label=""
            icon="mdi-delete-outline"
            title="Remove Question Relation"
            text="Are you sure you want to remove this relation from the question?"
            color="primary"
            confirmText="Yes, Remove"
            @confirm="remove(item)"
          />
        </div>
      </template>
      </v-data-table>
    </v-card>

    <relation-add-modal 
      v-model="showAddRelationDialog"
      :question="question"
      @cancel="showAddRelationDialog = false"
      @save="onRelationsUpdated"
    />

  </div>
</template>

<script>
import _ from 'lodash'
import { questions as questionsApi } from '@/services/api'
import ConfirmationDialog from '@/components/dialogs/confirmation.vue'
import RelationAddModal from './relation.add.modal.vue'

export default {
  components: {
    ConfirmationDialog, 
    RelationAddModal
  },
  props: {
    question: { type: Object, required: true },
    
  },
  data() {
    return {
      items: [],
      relations: [],
      headers: [
        { text: 'Name', value: 'name', width: '90%' },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      footerProps: {
        showItemsPerPage: false
      },
      showAddRelationDialog: false
    }
  },
  computed: {
    sorted(){
      return _.orderBy(this.items, ['name'], ['asc'])
    }
  },
  watch:{
    question(){
      this.load()
    }
  },
  mounted() {
      this.load()
  },
  methods: {
    async load() {
      if(this.question?.id){
        this.relations = await questionsApi.relations(this.question.id)
        this.items = this.relations.map(r => {
            return {
              id: r.id,
              entityId: r.entityId,
              entityType: r.entityType,
              name: r.procedureName ?? r.topicName ?? r.componentName ?? r.moduleName,
            }
          }
        )
      }
    },
    add(){
      this.showAddRelationDialog = true
    }, 
    async remove(item){
      await questionsApi.removeRelation(this.question.id, item.id)
      this.items.splice(this.items.indexOf(item), 1)
    }, 
    onRelationsUpdated(){
      this.load()
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
