var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-list',{attrs:{"title":"Modules","to":{ name: 'module-create' },"to-label":"Add","headers":_vm.headers,"items":_vm.modules,"paging":_vm.paging,"sortBy":_vm.sortBy,"sortDesc":_vm.sortDesc,"filterCount":_vm.filterCount},on:{"search":_vm.onSearch,"clear":_vm.onSearchClear,"page:changed":_vm.onPageChange,"row:view":_vm.view,"sortBy:changed":_vm.onSortByChanged,"sortDesc:changed":_vm.onSortDescChanged},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('v-checkbox',{attrs:{"value":"1","label":"Include archived modules"},on:{"click":function($event){_vm.includeArchived = !_vm.includeArchived}},model:{value:(_vm.advancedFilters),callback:function ($$v) {_vm.advancedFilters=$$v},expression:"advancedFilters"}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","pa-5":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":_vm.showSort}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sort")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Set Sort Order")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.version + ' / ' + item.status)+" ")])]}},{key:"item.publishDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.publishDateTime))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }