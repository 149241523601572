<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Topics</div>
      <v-spacer></v-spacer>
      <v-btn v-policy:module-update="module" @click="addTopic" color="primary">
        Add
      </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="innerTopics"
        item-key="id"
        @click:row="viewTopic"
        :disable-pagination="true"
        :hide-default-footer="true"
        disable-sort
      >
      <template v-slot:item.name="{item}">
        {{ item.name | truncate(250) }}
      </template>

      <template v-slot:item.actions="{item, index}">
        <v-btn
          v-if="index > 0"
          icon
          small
          title="Move Up"
          @click.stop="moveItem(item, index -1 )"
          v-policy:module-update="module"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          v-if="index < topics.length - 1"
          icon
          small
          title="Move Down"
          @click.stop="moveItem(item, index + 1)"
          v-policy:module-update="module"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      </v-data-table>
      <div class="text-right">
        <v-spacer></v-spacer>
        <v-btn text v-if="numberArchived > 0" class="text-right pa-2 text-caption text-lowercase" small @click="showArchived = !showArchived" > {{  showArchivedText }}</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { components as componentsApiBuilder } from '@/services/api'

export default {
  props: {
    module: { type: Object, required: true },
    component: { type: Object, required: true },
    topics: { type: Array, required: true }
  },
  data() {
    return {
      componentsApi: null,
      innerTopics: [],
      headers: [
        { text: 'Name', value: 'name', width: '75%' },
        { text: 'Status', value: 'status', width: '15%' },
        { text: 'Version', value: 'version', width: '10%', align: 'right' },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      footerProps: {
        showItemsPerPage: false
      },
      showArchived: false
    }
  },
  computed:{
    showArchivedText(){
      return this.showArchived ? 'hide archived' : `show ${this.numberArchived} archived topics`
    }, 
    numberArchived(){
      return this.topics.filter(item => item.status === 'archived').length
    }
  },
  watch: {
    showArchived(){
      this.filter()
    },
    topics(val){
      this.sortAndFilter(val)
    }
  },
  mounted() {
    this.componentsApi = componentsApiBuilder.build(this.module.id)
    if (this.topics){
      this.sortAndFilter(this.topics)
    }
  },
  methods: {
    sortAndFilter(val){
      if(!val) return
      this.sort(val)
      this.filter()
    },
    filter(){
      if(!this.innerTopics) return
      if(!this.showArchived){
        this.innerTopics =  this.innerTopics.filter(item => item.status !== 'archived')
      }
      else{
        this.sort(this.topics)
      }
    },
    sort(val){
      if(!val) return

      const orderSorter = 'order'
      const nameSorter = item => item.name.toLowerCase()

      const arr  = _.sortBy(val, [orderSorter, nameSorter])
      arr.forEach((item, idx) => {
        item.order = idx
      })  

      this.innerTopics = [...arr]
    },
    addTopic() {
      this.$emit('addTopic')
    },
    viewTopic(item) {
      this.$emit('viewTopic', item)
    },
    async moveItem(item, idx){
      this.innerTopics.splice(idx, 0, this.innerTopics.splice(item.order, 1)[0])
      this.innerTopics.forEach((item, idx) => {
        item.order = idx
      })
      
      await this.componentsApi.updateTopicOrder(this.component.id, Object.fromEntries(this.innerTopics.map(item => [item.id, item.order])))

      this.$emit('topicsUpdated', this.innerTopics)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
