<template>
  <v-card flat tile>
    <v-toolbar v-if="editable" dense elevation="2" color="grey lighten-4 mb-1">
      <v-toolbar-title>Upload Video</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text class="text-center">
      <video
        id="video-preview"
        controls
        :src="dataUrl"
        style="width: 100%; max-width: 450px"
      ></video>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    editable: { type: Boolean, default: false },
    value: { type: File, required: true }
  },
  data() {
    return {
      dataUrl: null,
      file: null, 
      video: null
    }
  },
  mounted() {
    this.loadVideo(this.value)
  },
  methods: {
    loadVideo(file) {
      if (file) {
        const fr = new FileReader()
        fr.onload = this.createVideo
        fr.readAsDataURL(file)
      }
    }, 
    createVideo(reader){
      const dataUrl = reader.srcElement.result
      this.dataUrl = dataUrl
      const video = document.getElementById('video-preview')
      video.src = dataUrl
    }
  },
  destroyed() {
    if (this.dataUrl) {
      URL.revokeObjectURL(this.dataUrl)
    }
  }
}
</script>

<style></style>
