<template>
  <v-dialog 
    v-model="show" 
    max-width="600px" 
    min-width="350px" 
    persistent
    scrollable
  >
    <v-card>
      <v-app-bar flat elevation="0">
        <v-toolbar-title>Add Question Relations</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
        <v-sheet class="mx-4 px-4 pb-2">
        <v-text-field
          v-model="search"
          label="Filter Content"
          flat
          
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-sheet>
        <v-card-text>
        <v-treeview
          v-model="selection"
          :items="items"
          :selection-type="selectionType"
          :search="search"
          :open.sync="open"
          selected-color="primary lighten-2"
          dense
          selectable
          return-object
          open-all
        ></v-treeview>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" plain>
          Cancel
        </v-btn>
        <v-btn @click="save" color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { questions as questionsApi } from '@/services/api'
import { lookups as lookupsApi } from '@/services/api'

export default {
  components: {},
  props: {
    question: { type: Object, required: true },
    value: Boolean
  },
  data() {
    return {
      search: null,
      selection: [],
      selectionType: 'independent',
      resetSignal: false,
      items: [],
      open: [1, 2],
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show() {}
  },
  mounted(){
    this.load()
  },
  methods: {
    async load(){
      this.items = await lookupsApi.treeview();
    },
    cancel() {
      this.show = false
      this.resetSignal = true
      this.$emit('cancel')
    },
    async save(model) {
      const relations = this.selection.map(s => {
        return {
          entityId: s.id,
          entityType: s.entityType
        }
      })
      await questionsApi.addRelations(this.question.id, {items: relations})

      this.selection = []
      this.show = false
      this.$emit('save', model)
    }
  }
}
</script>
