<template>
  <div>
    <app-toolbar :crumbs="localCrumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        plain
        v-policy:module-update="module"
        @click="showEditDialog = true"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
           
            <v-list-item
              v-policy:module-publish="module"
              @click="copy"
            >
              <v-list-item-icon>
                <v-icon>mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Copy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
           
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <div v-if="model">
      <v-card tile flat >
        <v-card-text>
          <text-viewer v-model="model.text" />
        </v-card-text>
      </v-card> 

      <v-card max-width="100%" min-width="350px" flat v-if="model.mediaItem && model.mediaItem.mediaId">
        <v-responsive>
          <media-preview
            :mediaId="model.mediaItem.mediaId"
            height="350"
          >
          </media-preview>
        </v-responsive>
      </v-card>

      <h4 class="mt-2">Choices</h4>
      <div>Answer Type: {{ answerType }}</div>

      <div v-for="choice in model.choices">
        <v-row no-gutters>
          <v-col cols="1">
            <v-checkbox
              class="mt-0"
              v-if="model.questionType === 'checkbox'"
              v-model="choice.isAnswer"
              dense
              disabled
            ></v-checkbox>
            <v-radio-group
              v-if="model.questionType === 'radio'"
              dense
              v-model="choice.isAnswer"
            >
              <v-radio
                class="mt-0"
                dense
                disabled
                :value="true"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="10">
            <text-viewer v-model="choice.label" class="mt-5"/>
            <media-preview
              v-if="choice.mediaItem"
              :mediaId="choice.mediaItem.mediaId"
              height="250"
            ></media-preview>
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="model.id">
      <relations-table :question="model"></relations-table>
    </div>

    <question-edit-modal
      v-model="showEditDialog"
      :mediaOptions="mediaOptions"
      :question="model"
      title="Edit Question"
      @delete="onDelete"
      @save="onSave"
      @cancel="showEditDialog = false"
    />

    <question-copy-modal
      v-model="showCopyDialog"
      :question="model"
      @save="onCopy"
      @cancel="showCopyDialog = false"/>
  </div>
</template>

<script>
import _ from 'lodash'
import { questions as questionsApi } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import MediaPreview from '@/components/media/media-preview.vue'
import TextViewer from '@/components/text/viewer.vue'
import RelationsTable from './relations-table.vue'
import QuestionEditModal from '@/components/knowledgeCheck/questions/edit.modal.vue'
import QuestionCopyModal from './copy.modal.vue'

export default {
  mixins: [contentPageMixin],
  components:{
    MediaPreview,
    QuestionCopyModal,
    QuestionEditModal,
    RelationsTable,
    TextViewer
  }, 
  data(){
    return {
      loaded: false,
      model: {},
      relations: [],
      showCopyDialog: false,
      showEditDialog: false
    }
  },
  computed: {
    answerType(){
      if(this.model){
        return this.model.questionType == 'radio' ? 'Single Answer' : 'Multiple Answers'
      }
      return ''
    },
    questionId() {
      return this.$route.params.questionId
    },
    localCrumbs(){
      const items = [
        {
          text: 'Questions',
          to: { name: 'questions-index', params: {} }
        }
      ]

      if(this.model){
        items.push({text: _.truncate(this.model.text, {length: 40}), disabled: true})
      }

      return items
    },
    mediaOptions() {
      return {
        showUpload: true,
        title: 'Media Selector',
        entityId: this.questionId,
        entityType: 'question'
      }
    }
  }, 
  watch: {
    questionId(newVal){
      this.load(newVal)
    }
  },
  mounted(){
    if(this.questionId){
      this.load(this.questionId)
    }
  },
  methods:{
    async load(id){
      this.loaded = false
      this.model = await questionsApi.fetch(id)
      this.relations = await questionsApi.relations(id)
      this.loaded = true
    },
    copy(){
      this.showCopyDialog = true
    },
    async onDelete(){
      this.$router.go(-1)
    }, 
    async onCopy(model){
      this.showCopyDialog = false
      this.$router.push({name: 'question-detail', params: {questionId: model.id}})
    },
    async onSave(){
      this.showEditDialog = false
      await this.load(this.questionId)
    }
  }
}

</script>