import ProcedureCreate from '@/views/procedure/create.vue'
import ProcedureEdit from '@/views/procedure/edit.vue'
import ProcedureDetail from '@/views/procedure/detail.vue'
import ProcedureKnowledgeCheck from '@/views/procedure/knowledgecheck.vue'

export default [
  {
    path: '/modules/:moduleId/component/:componentId/procedure/new',
    name: 'procedure-create',
    component: ProcedureCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/procedure/:procedureId',
    name: 'procedure-detail',
    component: ProcedureDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/procedure/:procedureId',
    name: 'procedurestep-detail',
    component: ProcedureDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/procedure/:procedureId/edit',
    name: 'procedure-edit',
    component: ProcedureEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/procedure/:procedureId/knowledgecheck',
    name: 'procedure-knowledgecheck',
    component: ProcedureKnowledgeCheck,
    meta: {
      authenticated: true
    }
  }
]
