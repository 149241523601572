<template>
  <v-hover v-slot="{ hover }">
    <v-card
      @dragover="dragging = true"
      @drop="dragging = false"
      @dragleave="dragging = false"
      :class="[dragging || hover ? 'lighten-1' : '']"
      flat
      color="#f5f5f5"
    >
      <form enctype="multipart/form-data" novalidate id="fileInputForm">
        <v-row
          class="fill-height"
          justify="center"
          height="100%"
          width="100%"
          no-gutters
        >
          <v-col>
            <input
              type="file"
              name="file"
              :multiple="false"
              @change="onFileSelected($event)"
              @click="onFileClicked($event)"
              :accept="accept"
              class="input-file"
            />
            <slot>
              <v-btn text icon class="pa-2 grey lighten-4">
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </slot>
          </v-col>
        </v-row>
      </form>

      <v-dialog v-model="dialog" width="85%" persistent>
        <v-card>
          <component :is="mediaForm" v-model="file" :editable="true" />

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="onCancel">Cancel</v-btn>

            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="acceptFile"> Accept </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-hover>
</template>

<script>
/*
- Show media upload buttom/trigger
- show 'form' for uploaded media type
- allow form to crop/resize or select thumnails etc.
- modal close/done raises event back to parent that contains file objects for the parent to upload to the server
*/
//import { STATUS_SUCCESS } from './constants'
import uploadBase from './upload-base.vue'
export default {
  mixins: [uploadBase],

  props: {
    accept: { type: String, default: 'image/*' }
  },
  data() {
    return {
      file: null,
      fileType: null,
      dialog: false
    }
  },
  computed: {
    mediaForm() {
      if (!this.fileType) {
        return null
      }

      return `${this.fileType}-form`
    }
  },
  methods: {
    async onFilesUploaded(files) {
      if (files.length) {
        this.uploading = true

        await this.uploadMediaItem(files[0])

        this.uploading = false
      }
    },
    async uploadMediaItem(file) {
      this.file = file
      const fileType = file.type.split('/').shift()
      this.fileType = fileType == 'application' ? 'document' : fileType

      this.dialog = true
    },
    async onCancel() {
      this.$emit('onCancel')
      this.close()
    },
    async acceptFile() {
      this.$emit('onUploaded', { fileType: this.fileType, file: this.file })
      this.close()
    },
    onFileClicked(event){
      event.target.value = null
    },
    onFileSelected(event) {
      this.filesChange(event.target.name, event.target.files)
      this.fileCount = event.target.files.length
      this.onFilesUploaded(event.target.files)
    },
    close(){
      const form = document.getElementById('fileInputForm')
      if(form) form.reset()
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
//deep//
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  min-height: 35px;
  position: absolute;
  display: flex;
  cursor: pointer;
  z-index: 1;
}
</style>
