import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import mediaQueue from '@/services/api/media/queue'
import authService from '../services/auth.service'
import rootRoutes from './root.routes'
import organizationRoutes from './organization.routes'
import memberRoutes from './member.routes'
import moduleRoutes from './module.routes'
import componentRoutes from './component.routes'
import groupRoutes from './group.routes'
import groupModuleRoutes from './groupModule.routes'
import mediaRoutes from './media.routes'
import procedureRoutes from './procedure.routes'
import questionRoutes from './question.routes'
import topicRoutes from './topic.routes'
import reportRoutes from './report.routes'

Vue.use(VueRouter)

let routes = []

routes = routes
  .concat(rootRoutes)
  .concat(organizationRoutes)
  .concat(memberRoutes)
  .concat(moduleRoutes)
  .concat(componentRoutes)
  .concat(groupRoutes)
  .concat(groupModuleRoutes)
  .concat(mediaRoutes)
  .concat(procedureRoutes)
  .concat(questionRoutes)
  .concat(topicRoutes)
  .concat(reportRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach(() => {
  mediaQueue.start()
})

router.beforeEach(async (to, from, next) => {
  //Cancel all pending media requests
  mediaQueue.cancelAll()

  if (from == VueRouter.START_LOCATION) {
    await store.dispatch('user/setUser')
  }

  if (to.meta.authenticated) {
    const user = await authService.getUser()

    if (!user || user.expired) {
      window.sessionStorage.setItem('callback-path', to.path)
      window.sessionStorage.setItem('callback-path-failed', from.path)
      await authService.login()
    } else if (to.meta.role && !authService.isUserInRole(user, to.meta.role)) {
      return next(`/unauthorized?role=${to.meta.role}`)
    }
  }

  await store.dispatch('context/load', { to })

  next()
})

router.afterEach(async (to) => {
  store.dispatch('setTitle', {
    title: to.meta.title || 'Admin'
  })
})

export default router
