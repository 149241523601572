<template>
  <v-sheet :style="containerStyle" class="d-flex justify-center align-center ma-0 pa-0">
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      width="100%"
      type="image"
    ></v-skeleton-loader>
    <div v-if="mediaItem">
      <component
        :is="`${mediaItem.mediaType}-preview`"
        :value="mediaItem"
        :height="height"
        :noCache="noCache"
        :resolution="resolution"
      />
    </div>
  </v-sheet>
</template>

<script>
import { media } from '@/services/api'

export default {
  props: {
    mediaId: { type: String, required: true },
    referenceId: { type: String, default: null },
    height: { type: String, default: 'auto' },
    noCache: { type: Boolean, default: false },
    resolution: { type: String, default: 'original' }
  },
  data() {
    return {
      loading: false,
      mediaItem: null,
      mediaItemReference: null, 
    }
  },
  computed:{
    containerStyle() {
      return {
        height: this.height,
        width: this.width,
        'max-height': this.maxHeight,
        'max-width': this.maxWidth
      }
    }
  },
  watch: {
    mediaId(v) {
      console.log('mediaId changed',v)
      this.loadMedia(v)
    }
  },
  mounted() {
    this.loadMedia(this.mediaId)
  },
  methods: {
    async loadMedia(mediaId) {
      if(!mediaId) {
        return
      }
      
      try {
        this.loading = true
        const response = await media.api.fetch(mediaId)
        this.mediaItem = response

        if(this.referenceId) {
          const response = await media.references.fetch(mediaId, this.referenceId)
          this.mediaItemReference = response.data
        }

      } catch (err) {
        console.log('failed to load media item', err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>