<template>
  <v-dialog
    v-model="show"
    max-width="800px">
    <v-card tile flat>
      <v-app-bar flat elevation="0">
        <v-toolbar-title>Copy Question</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
        <v-textarea
          width="100%"
          label="Question Text"
          auto-grow
          v-model="model.text"
          :rules="questionTextRules"
          validate-on-blur
          counter="1000"
        >
        </v-textarea>
        <v-checkbox
          v-model="model.copyRelations"
          label="Copy Relations"/>
        </v-form>
      </v-card-text>
    
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" plain>
          Cancel
        </v-btn>
        <v-btn @click="save" color="primary">
          Copy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { questions as questionsApi } from '@/services/api'
export default{
  props: {
    question: { type: Object, required: true },
    value: Boolean
  },
  data() {
    return {
      model: {},
      questionTextRules: [
        (v) => !!v || 'Question Text is required',
      ],
      valid: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        if(value){
          this.model = {
            text: `COPY - ${this.question.text}`
          }
        }
        this.$emit('input', value)
      }
    }
  },
  watch: {
    question(){
      this.model = {
        text: `COPY - ${this.question.text}`
      }
    },
    show() {}
  },
  methods:{
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    async save(){
      const result = await questionsApi.copy(this.question.id, this.model)
      this.show = false
      console.log(result)
      this.$emit('save', result)
    }
  }
}
</script>