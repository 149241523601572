import apiClient from './clients/api-client'
import { API_URL } from '@/config'

const path = `${API_URL}/organizations`
const configOptions = { _ignoreTenant: true }

const client = apiClient.build(path, configOptions)

client.restore = (id) => {
  const restorePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api
    .put(restorePath, null, configOptions)
    .then((response) => response.data)
}

client.getModuleSortOrder = () => {
  const p = `${path}/modules/order`
  return client.__api.get(p).then((response) => response.data)
}

client.setModuleSortOrder = (params) => {
    const p = `${path}/modules/order`
    params = params || {}
    return client.__api.put(p, params).then((response) => response.data)
}


export default client
