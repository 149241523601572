<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <topic-form
      :value="topic"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import contentPageMixin from '@/components/mixins/content-page'
import coverImage from '@/components/mixins/cover-image'
import { topics as topicsApiBuilder } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import topicForm from './form'

export default {
  mixins: [contentPageMixin, coverImage],
  components: {
    topicForm
  },
  data() {
    return {
      model: {},
      content: null
    }
  },
  computed: {
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Edit',
        disabled: true
      })
      return items
    },
    coverImage() {
      if (!this.model?.coverImageId) {
        return null
      }

      return `${this.model.mediaType}-preview`
    },
    mediaSelectorOptions() {
      return {
        entityId: this.topic.id,
        entityType: 'topic',
        moduleId: this.module.id,
        componentId: this.component.id,
        selected: this.topic.coverImageId,
        coverApi: this.topicsApi
      }
    }
  },
  async mounted() {
    this.topicsApi = topicsApiBuilder.build(this.module.id, this.component.id)

    if (this.topic) {
      this.model = _.cloneDeep(this.topic)
    }
  },
  watch: {
    topic(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('context', ['loadTopic', 'clearTopic']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model, file) {
      await this.topicsApi.put(
        model.id,
        _.pick(model, ['id', 'name', 'description', 'content'])
      )

      if (model.coverImageId) {
        await this.saveCoverImage(model, 'topic')

        if (this.model.coverImageId != model.coverImageId) {
          await this.topicsApi.cover(model.id, model.coverImageId)
        }
      } else if (file) {
        const media = {
          name: model.name,
          description: model.description,
          entityId: model.id,
          entityType: 'topic'
        }

        const mediaResponse = await mediaApi.api.postFile(file, media)
        model.coverImage = mediaResponse.id
        model.coverImageId = mediaResponse.referenceId

        await this.topicsApi.cover(model.id, model.coverImageId)
      }
      this.loadTopic(this.model.id)

      await this.clearTopic()

      this.$router.push({
        name: 'topic-detail',
        params: {
          moduleId: this.module.id,
          componentId: this.component.id,
          topicId: model.id
        }
      })

      this.addMessage({
        message: `Topic '${this.model.name}' has been updated.`
      })
    }
  }
}
</script>

<style>
pre {
  border-radius: 5px;
  color: #333;
}

code {
  display: block;
  white-space: pre-wrap;
  font-size: 0.8rem;
  padding: 0.75rem 1rem;
  background-color: #e9ecef;
  color: #495057;
}
</style>
