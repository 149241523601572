<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app
    clipped
    permanent
  >
    <v-list-item dense @click="toggle">
      <v-list-item-icon title="collapse/expand">
        <v-icon>mdi-menu</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="title"> </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <component :is="menuComponent"></component>

    <!-- Management of organizations was moved to unified identity server 
        Integration in this app may happen later -->
    <!-- <template v-slot:append>
      <v-list dense nav>
        <v-divider></v-divider>
        <v-list-item
          v-if="layout == 'default'"
          link
          title="Organizations"
          :to="{ name: 'organization-index' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>System Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-else
          link
          exact="true"
          title="Return"
          :to="{ name: 'Home' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Return</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { IDENTITY_SERVER_URL } from '@/config'
import profileMixin from '@/components/mixins/profile'

export default {
  mixins: [profileMixin],
  props: {
    layout: { type: String, default: 'default' }
  },
  data() {
    return {
      drawer: true,
      isCollapsed: false,
      isExpanded: false,
      mini: false,
      memberLink: `${IDENTITY_SERVER_URL}/org/members`,
      organizationLink: `${IDENTITY_SERVER_URL}/admin/organizations`
    }
  },
  computed: {
    ...mapGetters('user', ['organization', 'isSysAdmin', 'isOrgAdmin']),
    menuComponent() {
      return `${this.layout}-nav-menu`
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    toggle() {
      if (this.mini) {
        this.isExpanded = !this.$vuetify.breakpoint.mdAndUp
        this.isCollapsed = false
        this.mini = false
      } else {
        this.isExpanded = false
        this.isCollapsed = true
        this.mini = true
      }
    },
    onResize() {
      if (
        this.isCollapsed ||
        (this.isExpanded && this.$vuetify.breakpoint.smAndDown)
      ) {
        return
      }

      this.isCollapsed = false
      this.isExpanded = false
      this.mini = this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style scoped>
.v-list-item__title {
  text-transform: uppercase;
}
</style>
