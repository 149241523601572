<template>
  <div class="mt-2">
    <v-row class="heading">
      <v-col class="text-h6">Components</v-col>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          @click="addComponent"
          v-policy:module-update="module"
          color="primary"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer />
    <v-card>
    <v-data-table
      :headers="headers"
      :items="innerComponents"
      item-key="id"
      @click:row="viewComponent"
      :disable-pagination="true"
      :hide-default-footer="true"
      disable-sort
    >
    <template v-slot:item.name="{item}">
      {{ item.name | truncate(250) }}
    </template>
    <template v-slot:item.actions="{item, index}">
      <v-btn
        v-if="index > 0"
        icon
        small
        title="Move Up"
        @click.stop="moveItem(item, index -1 )"
        v-policy:module-update="module"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn
        v-if="index < components.length - 1"
        icon
        small
        title="Move Down"
        @click.stop="moveItem(item, index + 1)"
        v-policy:module-update="module"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    </v-data-table>
    <div class="text-right">
        <v-spacer></v-spacer>
        <v-btn text v-if="numberArchived > 0" class="text-right pa-2 text-caption text-lowercase" small @click="showArchived = !showArchived" > {{  showArchivedText }}</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { modules as modulesApi } from '@/services/api'

export default {
  props: {
    module: { type: Object, required: true },
    components: { type: Array, required: true }
  },
  data() {
    return {
      innerComponents: [],
      headers: [
        { text: 'Name', value: 'name', width: '75%' },
        { text: 'Status', value: 'status', width: '15%' },
        {
          text: 'Version',
          value: 'version',
          width: '10%',
          sortable: true,
          align: 'right'
        },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      showArchived: false
    }
  },
  computed:{
    showArchivedText(){
      return this.showArchived ? 'hide archived' : `show ${this.numberArchived} archived components`
    }, 
    numberArchived(){
      return this.components.filter(item => item.status === 'archived').length
    }
  },
  watch: {
    showArchived(){
      this.filter()
    },
    components(val){
      this.sortAndFilter(val)
    }
  },
  mounted() {
    if (this.components){
      this.sortAndFilter(this.components)
    }
  },
  methods: {
    sortAndFilter(val){
      if(!val) return
      this.sort(val)
      this.filter()
    },
    filter(){
      if(!this.innerComponents) return
      if(!this.showArchived){
        this.innerComponents =  this.innerComponents.filter(item => item.status !== 'archived')
      }
      else{
        this.sort(this.components)
      }
    },
    sort(val){
      if(!val) return

      const orderSorter = 'order'
      const nameSorter = item => item.name.toLowerCase()

      const arr  = _.sortBy(val, [orderSorter, nameSorter])
      arr.forEach((item, idx) => {
        item.order = idx
      })  

      this.innerComponents = [...arr]
    },
    addComponent() {
      this.$emit('addComponent')
    },
    viewComponent(item) {
      this.$emit('viewComponent', item)
    }, 
    async moveItem(item, idx){
      this.innerComponents.splice(idx, 0, this.innerComponents.splice(item.order, 1)[0])
      this.innerComponents.forEach((item, idx) => {
        item.order = idx
      })
      
      await modulesApi.updateComponentOrder(this.module.id, Object.fromEntries(this.innerComponents.map(item => [item.id, item.order])))

      this.$emit('componentsUpdated', this.innerComponents)
    }
  }
}
</script>
