<template>
  <div class="text-center">
    <v-dialog v-model="show" class="modal" persistent width="500">
      <v-card>
        <v-card-title class="headline" style="text-transform: capitalize">
          Delete Question
        </v-card-title>

        <v-card-text>
          <p>
            Are you sure you want to permanantly delete this question?
          </p>
          <p>This content will be gone forever.</p>

          <slot name="text" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" plain @click="cancel"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" tile large @click="onDelete">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      confirmName: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('onDelete')
    }
  }
}
</script>
